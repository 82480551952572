import { Location } from './location';
import { onThemeChanged } from './theme-switcher';

function toPascalCase(str: string): string {
    return str
        .split(/[\s_\/-]+/i)
        .filter(Boolean)
        .map((part) => part[0].toUpperCase() + part.slice(1))
        .join(' ');
}

const stories = Array.from(document.querySelectorAll<HTMLElement>('#canvas > .story')).map(
    (story) => {
        const [title, group] = story.id.split('/').reverse();
        return {
            id: story.id,
            group: toPascalCase(group),
            title: toPascalCase(title),
            fullTitle: story.querySelector('.story__title')!.textContent,
            theme: story.dataset.theme || '*',
        };
    },
);

const sidebar = document.querySelector('#sidebar')!;
const storyLinks = new Map();
const availableThemes = new Set<string>(globalThis.STYLEGUIDE_THEMES.map(({ id }) => id));
let activeTheme = '*';

for (const [group, groupStories] of Object.entries(Object.groupBy(stories, ({ group }) => group))) {
    if (!Array.isArray(groupStories) || groupStories.length == 0) {
        continue;
    }

    const groupTitle = document.createElement('h6');
    groupTitle.innerHTML = group ? group[0].toUpperCase() + group.slice(1) : 'Styleguide';
    sidebar.appendChild(groupTitle);

    const list = document.createElement('ul');
    list.role = 'list';
    sidebar.appendChild(list);

    const groupThemes = new Set<string>();

    for (const story of groupStories) {
        const item = document.createElement('li');
        list.appendChild(item);

        const link = document.createElement('a');
        link.href = `#${story.id}`;
        link.textContent = story.title;
        item.appendChild(link);

        groupThemes.add(story.theme);
        if (story.theme !== '*') {
            item.classList.add(`theme:${story.theme}`);
        }

        storyLinks.set(story.id, link);
    }

    if (groupThemes.size === 1) {
        const theme = groupStories[0].theme;
        if (theme !== '*') {
            groupTitle.classList.add(`theme:${theme}`);
            list.classList.add(`theme:${theme}`);
        }
    }
}

const overriddenStories = new Map<string, string[]>();

for (const story of stories) {
    if (story.theme === '*') {
        const overrides = stories.filter(
            (s) => s.title === story.title && s.group === story.group && s.theme !== '*',
        );
        if (overrides.length > 0) {
            overriddenStories.set(
                story.id,
                overrides.map(({ theme }) => theme),
            );
        }
    }
}

if (availableThemes.size > 0) {
    const style = document.createElement('style');
    style.textContent = `
        #sidebar [class*="theme:"] { display: none; }
        ${[...availableThemes]
            .map((theme) => `:root.theme\\:${theme} #sidebar .theme\\:${theme} { display: block; }`)
            .join('\n')}
        ${Array.from(overriddenStories.entries())
            .map(([storyId, overrides]) =>
                overrides
                    .map(
                        (theme) => `
                        :root.theme\\:${theme} #sidebar li:has(a[href="#${storyId}"]) { display: none; }
                    `,
                    )
                    .join('\n'),
            )
            .join('\n')}
    `;
    document.head.appendChild(style);
}

function setActiveStory(storyId) {
    if (!storyId || !storyLinks.has(storyId)) {
        storyId = stories.find((story) => story.theme == '*' || story.theme == activeTheme)!.id;
    }
    for (const [id, link] of storyLinks) {
        link.classList.toggle('is-active', id === storyId);
    }
    location.hash = storyId;
}

Location.subscribe(
    (state) => {
        setActiveStory(state.url.hash.slice(1));
    },
    { segments: ['hash'] },
);

onThemeChanged((themeId) => {
    if (activeTheme !== '*') {
        document.documentElement.classList.remove(`theme:${activeTheme}`);
    }
    if (!themeId) {
        themeId = 'default';
    }
    document.documentElement.classList.add(`theme:${themeId}`);

    activeTheme = themeId;

    const activeStory = document.querySelector(`#sidebar li:has(>a[href="${location.hash}"])`);
    if (
        activeStory &&
        !activeStory.classList.contains(`theme:${themeId}`) &&
        activeStory.className.includes('theme:')
    ) {
        setActiveStory(null);
    }
});
